import React, { useState } from "react";
import HeaderComponent from "components/Header/Header";
import Hero from "components/Hero/Hero";
import HealthcareServices from "components/HealthcareServices/HealthcareServices";
import HowWeWork from "components/HowWeWork/HowWeWork";
import WhyWorkWithUs from "components/WhyWorkWithUs/WhyWorkWithUs";
import BlueTextSection from "components/BlueTextSection/BlueTextSection";
import Faq from "components/Faq/Faq";
import Portfolio from "components/Portfolio/Portfolio";
import Testimonial from "components/Testimonial/Testimonial";
import ModalComponent from "components/ModalComponent/ModalComponent";
import ShareYourNeeds from "components/ShareYourNeeds/ShareYourNeeds";
import ScheduleCall from "components/ScheduleCall/ScheduleCall";
import Footer from "components/Footer/Footer";

export default function HomePage() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <HeaderComponent />
      <Hero />
      <HealthcareServices />
      <ShareYourNeeds />
      <HowWeWork />
      <WhyWorkWithUs />
      <BlueTextSection />
      <ScheduleCall />
      <Testimonial />
      <Portfolio />
      <Faq />
      <ModalComponent
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
      />
      <Footer />
    </>
  );
}
