import { styled } from "styled-components";
import { PrimaryColor } from "shared/styles/colors.constant";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";

export const Section = styled.section`
  padding: 30px 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${PrimaryColor.BASE_WHITE};
  h2 {
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 60px;
      height: 4px;
      left: -80px;
      top: 20px;
      background: ${PrimaryColor.BASE_RED};

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
`;

export const Title = styled.h2`
  position: relative;
  display: inline-block;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.LARGE_38};
  line-height: ${LineHeight.SM};
  color: ${PrimaryColor.TITLE_BLACK};
  text-align: center;

  @media screen and (max-width: 768px) {
    padding-bottom: 15px;
    border-bottom: 4px solid ${PrimaryColor.BASE_RED};
  }
`;

export const List = styled.ul`
  width: 80%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Item = styled.li`
  max-width: 42%;
  margin: 4%;
  padding-right: 30px;
  @media screen and (max-width: 768px) {
    padding-right: 0px;
  }
`;

export const Text = styled.p`
  margin-top: 20px;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_16};
  line-height: ${LineHeight.SM};
  color: ${PrimaryColor.BLACK};
`;

export const CardTitle = styled.h3`
  display: inline-block;
  border-bottom: 2px solid ${PrimaryColor.BASE_RED};
  padding-bottom: 10px;
  padding-right: 50px;

  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.MEDIUM_20};
  font-weight: 500;
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.BLACK};

  @media screen and (max-width: 768px) {
    padding-right: 0px;
  }
`;
