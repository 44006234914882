import { styled } from "styled-components";
import { PrimaryColor } from "shared/styles/colors.constant";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  background: ${PrimaryColor.BASE_WHITE};
  border: 5px solid transparent;
  transition: all 0.3s ease-in-out;
  background: #015541;

  &:hover {
    border: 5px solid #00e9b2;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`;

export const Category = styled.p`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_15};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.LIGHT_GREY};
  font-weight: 500;
  margin-bottom: 10px;
`;

export const Title = styled.h1`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_18};
  font-weight: 600;
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.BASE_WHITE};
`;
