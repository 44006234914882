export const mailTo = "mailto:doctors@doctors365.org";

export const videoLink =
  "https://zenbit.tech/wp-content/uploads/2021/05/video.mp4";

export const animationDuration = {
  XS: 1000,
  SM: 1500,
  MD: 1700,
  LG: 2000,
};
