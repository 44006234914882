import React from "react";
import { Category, Container, Header, IconBox, Title } from "./styles";

interface IProps {
  children: React.ReactNode;
  icon: JSX.Element;
  category: string;
  title: string;
}

export default function ServiceCard({
  icon,
  children,
  category,
  title,
}: IProps) {
  return (
    <Container>
      <Header>
        <IconBox>{icon}</IconBox>
        <div>
          <Category>{category}</Category>
          <Title>{title}</Title>
        </div>
      </Header>
      {children}
    </Container>
  );
}
