import { styled } from "styled-components";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";
import { PrimaryColor } from "shared/styles/colors.constant";
import bgPic from "assets/icons/healthcare-сategory-icon.svg";

export const Section = styled.section`
  width: 100%;
  margin-bottom: 50px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  h2 {
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 60px;
      height: 4px;
      left: -80px;
      top: 20px;
      background: ${PrimaryColor.BASE_RED};
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
`;

export const Container = styled.div`
  min-width: 80%;
  width: 80%;
  height: 100%;
  margin: 20px auto 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-right: 25px; */
  border-radius: 20px;
  background: ${PrimaryColor.BASE_WHITE};
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.17);

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Title = styled.h2`
  position: relative;
  display: flex;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.LARGE_38};
  line-height: ${LineHeight.SM};
  color: ${PrimaryColor.TITLE_BLACK};
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 60px;
    height: 4px;
    left: -80px;
    top: 20px;
    background: ${PrimaryColor.BASE_RED};
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 15px;
    border-bottom: 4px solid ${PrimaryColor.BASE_RED};
  }
`;

export const LabelWrapper = styled.div`
  min-width: 134px;
  min-height: 32px;
  width: 134px;
  position: relative;
  top: -9px;
  background-image: url(${bgPic});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.p`
  display: inline;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_16};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.BASE_WHITE};
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.h3`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.MEDIUM_20};
  line-height: ${LineHeight.SM};
  color: ${PrimaryColor.TITLE_BLACK};
  margin-top: 20px;
  font-weight: 500;
`;

export const Text = styled.p`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_16};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.DARK_GREY};
  margin-top: 20px;
`;

export const List = styled.ul`
  width: 80%;
`;

export const Item = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.17);
  margin-top: 40px;
  padding: 0 20px 20px 0;
`;

export const ImageWrapper = styled.div`
  width: 350px;
  height: 290px;
  min-width: 350px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

export const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const Wrapper = styled.div``;

export const TextWrapper = styled.div`
  width: 50%;
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const VideoWrapper = styled.div`
  max-width: 50%;
  position: relative;

  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 260px;
    height: 77px;
    top: -18px;
    left: -18px;
    border-top-left-radius: 8px;
    border-left: 3px ${PrimaryColor.BASE_GREEN} solid;
    border-top: 3px ${PrimaryColor.BASE_GREEN} solid;
    @media screen and (max-width: 768px) {
      top: -8px;
      left: 20px;
    }
  }

  &::after {
    display: block;
    content: "";
    position: absolute;
    width: 260px;
    height: 77px;
    bottom: -18px;
    right: -18px;
    border-bottom-right-radius: 8px;
    border-right: 3px ${PrimaryColor.BASE_GREEN} solid;
    border-bottom: 3px ${PrimaryColor.BASE_GREEN} solid;

    @media screen and (max-width: 768px) {
      bottom: -8px;
      right: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Video = styled.video`
  width: 400px;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const PhotoWrapper = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
`;

export const ClientTitle = styled.h3`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_18};
  font-weight: 600;
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.DARK_GREY};
`;

export const JobTitle = styled.p`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_16};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.DARK_GREY};
`;

export const Description = styled.p`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_16};
  line-height: ${LineHeight.MD};
  font-style: italic;
  font-weight: 200;
  color: ${PrimaryColor.TITLE_BLACK};
  text-align: center;

  &::before {
    content: "“";
    font-size: 100px;
    color: ${PrimaryColor.LIGHT_GREY};
    font-family: Times New Roman, Times, serif;
    font-weight: 900;
    line-height: 1;
    display: block;
    height: 0.6em;
  }
`;
