import React from "react";
import { t } from "i18next";
import { Fade } from "react-awesome-reveal";

import photo from "assets/images/NataliyaNakonechna.webp";
import { animationDuration, mailTo } from "shared/constants";
import {
  AccentText,
  Btn,
  Container,
  Name,
  Photo,
  PhotoContainer,
  Section,
  SpecBox,
  Speciality,
  Text,
  TextContainer,
  Title,
} from "./styles";

export default function ScheduleCall() {
  return (
    <Fade duration={animationDuration.MD} direction="right" triggerOnce>
      <Section>
        <Container>
          <TextContainer>
            <Title>
              {t("call.title1")}
              <br />
              <AccentText>{t("call.title2")}</AccentText>
            </Title>
            <Text> {t("call.desc")}</Text>
            <Btn
              type="button"
              onClick={(e) => {
                window.location.href = mailTo;
                e.preventDefault();
              }}
            >
              {t("call.btn")}
            </Btn>
          </TextContainer>
          <PhotoContainer>
            <Photo src={photo} alt={t("call.alt")} />
            <SpecBox>
              <Name> {t("call.name")}</Name>
              <Speciality>
                {t("call.prof1")}
                <br />
                {t("call.prof2")}
              </Speciality>
            </SpecBox>
          </PhotoContainer>
        </Container>
      </Section>
    </Fade>
  );
}
