import React from "react";
import { t } from "i18next";
import { Fade } from "react-awesome-reveal";

import { animationDuration } from "shared/constants";
import { CardTitle, Item, List, Section, Text, Title } from "./styles";

export default function WhyWorkWithUs() {
  return (
    <Fade delay={200} duration={animationDuration.LG} triggerOnce>
      <Section>
        <Title>{t("whywork.title")}</Title>
        <List>
          <Item>
            <CardTitle>{t("whywork.title1")}</CardTitle>
            <Text>{t("whywork.text1")}</Text>
          </Item>
          <Item>
            <CardTitle>{t("whywork.title2")}</CardTitle>
            <Text>{t("whywork.text2")}</Text>
          </Item>
          <Item>
            <CardTitle>{t("whywork.title3")}</CardTitle>
            <Text>{t("whywork.text3")}</Text>
          </Item>
          <Item>
            <CardTitle>{t("whywork.title4")}</CardTitle>
            <Text>{t("whywork.text4")}</Text>
          </Item>
        </List>
      </Section>
    </Fade>
  );
}
