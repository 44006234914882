import React from "react";
import { t } from "i18next";
import { Fade } from "react-awesome-reveal";

import {
  Btn,
  ButtonContainer,
  Container,
  Img,
  ImgWrapper,
  Section,
  Text,
  Title,
} from "./styles";
import logoHIPAA from "assets/images/HIPAA.png";
import logoISO from "assets/images/ISO.png";
import logoGDPR from "assets/images/GDPR.png";
import { animationDuration, mailTo } from "shared/constants";

export default function Hero() {
  return (
    <Section id="nav">
      <Fade duration={animationDuration.SM} triggerOnce>
        <Container>
          <Title>{t("hero.title")}</Title>
          <Text>{t("hero.desc")}</Text>
          <ButtonContainer>
            <Btn
              type="button"
              onClick={(e) => {
                window.location.href = mailTo;
                e.preventDefault();
              }}
            >
              {t("hero.btn")}
            </Btn>
            <ImgWrapper>
              <Img src={logoHIPAA} alt={t("logo HIPAA")} />
              <Img src={logoISO} alt={t("logo ISO")} />
              <Img src={logoGDPR} alt={t("logo GDPR")} />
            </ImgWrapper>
          </ButtonContainer>
        </Container>
      </Fade>
    </Section>
  );
}
