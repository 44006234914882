import { styled } from "styled-components";
import { PrimaryColor } from "shared/styles/colors.constant";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";
import rectangle from "assets/icons/rectangle-icon.svg";
import bgPic from "assets/icons/blue-bg.svg";

export const Section = styled.section`
  padding: 30px 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 85%;
  padding: 4%;
  border-radius: 15px;
  display: flex;
  background-image: url(${bgPic});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  @media screen and (max-width: 768px) {
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  position: relative;
  display: inline-block;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.MEDIUM_26};
  line-height: ${LineHeight.SM};
  color: ${PrimaryColor.BASE_WHITE};
  text-align: center;
`;

export const Text = styled.p`
  margin-top: 20px;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_18};
  line-height: ${LineHeight.LG};
  color: ${PrimaryColor.BASE_WHITE};
  text-align: center;
`;

export const IconBox = styled.div`
  min-width: 25%;
  min-height: 270px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${rectangle});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const Image = styled.img`
  max-height: 55%;
  width: 65%;
  max-width: 170px;
  border-radius: 15px;
`;
