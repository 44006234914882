import { styled } from "styled-components";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";
import { PrimaryColor } from "shared/styles/colors.constant";

export const Section = styled.section`
  width: 100%;
  margin-bottom: 50px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  min-width: 80%;
  width: 80%;
  height: 100%;
  margin: 20px auto 0 auto;
  padding: 30px 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: ${PrimaryColor.BASE_WHITE};
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.17);
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.h2`
  padding-right: 10%;
  padding-left: 10%;
  width: 80%;
  display: flex;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.LARGE_38};
  line-height: ${LineHeight.SM};
  color: ${PrimaryColor.DARK_GREY};
  font-weight: 700;
  text-align: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Text = styled.p`
  padding-right: 10%;
  padding-left: 10%;
  margin-top: 30px;
  width: 80%;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_16};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.DARK_GREY};
  text-align: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Btn = styled.button`
  padding: 11px 50px;
  margin-top: 20px;
  border-radius: 50px;
  background: ${PrimaryColor.SECONDARY_GREEN};
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_18};
  line-height: ${LineHeight.SM};
  font-weight: 500;
  color: ${PrimaryColor.BASE_WHITE};
  border: none;
  height: fit-content;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${PrimaryColor.BASE_GREEN};
  }
`;
