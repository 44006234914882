import { PrimaryColor } from "shared/styles/colors.constant";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";
import { styled } from "styled-components";

export const FooterStyled = styled.footer`
  width: 100%;
  background: #2f303a;
  padding: 30px 10%;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Title = styled.h2`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.MEDIUM_20};
  line-height: ${LineHeight.SM};
  letter-spacing: 0.1rem;
  color: ${PrimaryColor.BASE_WHITE};
  margin-bottom: 15px;
  font-weight: 300;

  @media screen and (max-width: 768px) {
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const ContactsBlock = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Contact = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 15px;

  span svg {
    fill: ${PrimaryColor.BASE_WHITE};
    color: ${PrimaryColor.BASE_WHITE};
    width: 20px;
    height: 20px;
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const LinkStyled = styled.a`
  text-decoration: none;
  width: fit-content;
  margin-left: 15px;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_16};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.BASE_WHITE};
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${PrimaryColor.SECONDARY_GREEN};
    text-decoration: underline;
  }
  &:focus {
    color: ${PrimaryColor.SECONDARY_GREEN};
    text-decoration: underline;
  }
`;

export const LogoLink = styled.a`
  display: inline-block;
  width: fit-content;

  @media screen and (max-width: 768px) {
    margin-top: 20px;
  }
`;

export const LogoBox = styled.div`
  height: 40px;
  width: fit-content;

  svg {
    fill: ${PrimaryColor.BASE_WHITE};
    color: ${PrimaryColor.BASE_WHITE};
    transition: all 0.3s ease-in-out;

    &:hover {
      fill: ${PrimaryColor.SECONDARY_GREEN};
      color: ${PrimaryColor.SECONDARY_GREEN};
    }

    &:focus {
      fill: ${PrimaryColor.SECONDARY_GREEN};
      color: ${PrimaryColor.SECONDARY_GREEN};
    }
  }
`;
