import React from "react";
import { t } from "i18next";
import { Fade } from "react-awesome-reveal";
import { Collapse } from "antd";

import { animationDuration } from "shared/constants";
import {
  Container,
  Section,
  Title,
  FaqWrapper,
  Text,
  List,
  Item,
} from "./styles";
const { Panel } = Collapse;

export default function Faq() {
  return (
    <Fade duration={animationDuration.SM} triggerOnce>
      <Section>
        <Container>
          <Title id="faq">{t("faq.title")}</Title>
          <FaqWrapper>
            <Collapse>
              <Panel header={t("faq.title1")} key="1">
                <Text>{t("faq.text1")}</Text>
              </Panel>
              <Panel header={t("faq.title2")} key="2">
                <Text>{t("faq.text2")}</Text>
              </Panel>
              <Panel header={t("faq.title3")} key="3">
                <List>
                  <Item>{t("faq.text31")}</Item>
                  <Item>{t("faq.text32")}</Item>
                  <Item>{t("faq.text33")}</Item>
                  <Item>{t("faq.text34")}</Item>
                  <Item>{t("faq.text35")}</Item>
                  <Item>{t("faq.text36")}</Item>
                  <Item>{t("faq.text37")}</Item>
                </List>
              </Panel>
              <Panel header={t("faq.title4")} key="4">
                <Text>{t("faq.text4")}</Text>
              </Panel>
            </Collapse>
          </FaqWrapper>
        </Container>
      </Section>
    </Fade>
  );
}
