import React from "react";
import { t } from "i18next";

import {
  ModalHeader,
  ModalClose,
  InputWrapper,
  ModalFooter,
  ModalOk,
} from "./styles";
import { Input, Modal } from "antd";
const { TextArea } = Input;

interface IProps {
  handleOk: () => void;
  handleCancel: () => void;
  isModalOpen: boolean;
}

export default function ModalComponent({
  handleOk,
  handleCancel,
  isModalOpen,
}: IProps) {
  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      centered
      closable={false}
      width={620}
    >
      <ModalHeader>
        <ModalClose onClick={handleCancel}>X</ModalClose>
      </ModalHeader>
      <InputWrapper>
        <Input placeholder={t("modal.name")} size="large" />
        <Input placeholder={t("modal.email")} size="large" />
      </InputWrapper>
      <InputWrapper>
        <Input placeholder={t("modal.phone")} size="large" />
      </InputWrapper>
      <InputWrapper>
        <TextArea
          rows={4}
          placeholder={t("modal.message")}
          autoSize={{ minRows: 4, maxRows: 6 }}
        />
      </InputWrapper>
      <ModalFooter>
        <ModalOk type="button" onClick={handleOk}>
          {t("modal.send")}
        </ModalOk>
      </ModalFooter>
    </Modal>
  );
}
