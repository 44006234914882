import styled from "styled-components";
import { PrimaryColor } from "shared/styles/colors.constant";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";

export const Header = styled.header`
  width: 100%;
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 10px 3%;
  background: ${PrimaryColor.BASE_WHITE};
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.17);

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

export const LogoBox = styled.div`
  height: 60px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    height: 40px;
    margin-bottom: 10px;
    justify-content: center;
  }
`;

export const LogoImg = styled.img`
  height: 60px;
  width: 100%;
  @media screen and (max-width: 768px) {
    height: 100%;
    justify-content: center;
  }
`;

export const NavigationList = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-width: fit-content;
  @media screen and (max-width: 768px) {
    justify-content: space-evenly;
  }
`;

export const NavigationItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1%;
  margin-left: 1.5%;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.MEDIUM_20};
  font-weight: 500;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    font-size: ${FontSize.VW_LG};
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${PrimaryColor.DARK_GREY};
  transition: all 0.3s ease-in-out;
  display: flex;

  &:hover {
    color: ${PrimaryColor.BASE_GREEN};
  }
`;

export const Btn = styled.button`
  padding: 11px 20px;
  margin-left: 2%;
  border-radius: 50px;
  background: ${PrimaryColor.SECONDARY_GREEN};
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_18};
  line-height: ${LineHeight.SM};
  font-weight: 500;
  color: ${PrimaryColor.BASE_WHITE};
  border: none;
  white-space: nowrap;
  height: fit-content;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background: ${PrimaryColor.BASE_GREEN};
  }

  @media screen and (max-width: 768px) {
    font-size: ${FontSize.VW_SM};
    padding: 11px 3%;
  }
`;
