import { styled } from "styled-components";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";
import { PrimaryColor } from "shared/styles/colors.constant";

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  bottom: -50px;
`;

export const ModalOk = styled.button`
  padding: 19px 38px;
  background: ${PrimaryColor.SECONDARY_GREEN};
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_18};
  line-height: ${LineHeight.SM};
  font-weight: 600;
  color: ${PrimaryColor.BASE_WHITE};
  border: none;
  height: fit-content;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${PrimaryColor.BASE_GREEN};
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -60px;
  right: -55px;
`;

export const ModalClose = styled.button`
  background-color: transparent;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.MEDIUM_22};
  line-height: ${LineHeight.SM};
  font-weight: 700;
  color: ${PrimaryColor.BASE_WHITE};
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${PrimaryColor.BASE_RED};
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  gap: 20px;

  input {
    font-family: ${FontFamily.JOST} !important;
    font-size: ${FontSize.SMALL_16} !important;
    line-height: ${LineHeight.SM} !important;
  }

  textarea {
    font-family: ${FontFamily.JOST} !important;
    font-size: ${FontSize.SMALL_16} !important;
    line-height: ${LineHeight.SM} !important;
  }
`;
