import { styled } from "styled-components";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";
import { PrimaryColor } from "shared/styles/colors.constant";
import bgPic from "assets/icons/healthcare-сategory-icon.svg";

export const Section = styled.section`
  width: 100%;
  margin-bottom: 50px;
`;

export const Container = styled.div`
  min-width: 80%;
  width: 80%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 60px;
      height: 4px;
      left: -80px;
      top: 20px;
      background: ${PrimaryColor.BASE_RED};
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
`;

export const Title = styled.h2`
  position: relative;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.LARGE_38};
  line-height: ${LineHeight.SM};
  color: ${PrimaryColor.TITLE_BLACK};
  text-align: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding-bottom: 15px;
    border-bottom: 4px solid ${PrimaryColor.BASE_RED};
  }
`;

export const LabelWrapper = styled.div`
  min-width: 134px;
  min-height: 32px;
  width: 134px;
  position: relative;
  top: -9px;
  background-image: url(${bgPic});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    top: -29px;
  }
`;

export const Label = styled.p`
  display: inline;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_16};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.BASE_WHITE};
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.h3`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.MEDIUM_20};
  line-height: ${LineHeight.SM};
  color: ${PrimaryColor.TITLE_BLACK};
  margin-top: 20px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const Text = styled.p`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_16};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.DARK_GREY};
  margin-top: 20px;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const List = styled.ul`
  width: 80%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Item = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.17);
  margin-top: 40px;
  padding: 0 20px 20px 0;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 350px;
  max-height: 290px;
  width: 100%;
  height: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    margin-right: 0px;
  }
`;

export const Image = styled.img`
  /* max-height: 100%;
  max-width: 100%; */
  width: 100%;
  height: 100%;
  @media screen and (max-width: 768px) {
    max-width: 350px;
    max-height: 290px;
  }
`;
