export enum FontWeight {
  REGULAR = 400,
  MEDIUM = 500,
  SEMI_BOLD = 600,
  BOLD = 700,
  EXTRA_BOLD = 800,
  HEAVY = 900,
}

export enum FontStyle {
  NORMAL = "normal",
}

export enum FontFamily {
  JOST = "Jost",
}

export enum FontSize {
  SMALL_11 = "11px",
  SMALL_13 = "13px",
  SMALL_15 = "15px",
  SMALL_16 = "16px",
  SMALL_18 = "18px",
  MEDIUM_20 = "20px",
  MEDIUM_22 = "22px",
  MEDIUM_26 = "26px",
  MEDIUM_32 = "32px",
  LARGE_38 = "38px",
  LARGE_48 = "48px",
  LARGE_56 = "56px",
  LARGE_64 = "64px",
  VW_LG = "3.2vw",
  VW_MD = "3vw",
  VW_SM = "2.6vw",
}

export enum FontHeight {
  H_14 = "14px",
  H_15 = "15px",
  H_16 = "16px",
  H_18 = "18px",
  H_20 = "20px",
  H_21 = "21px",
  H_22 = "22px",
  H_24 = "24px",
  H_28 = "28px",
  H_30 = "30px",
  H_36 = "36px",
  H_44 = "44px",
  H_46 = "46px",
  H_52 = "52px",
  H_68 = "68px",
  H_78 = "78px",
  H_84 = "84px",
}

export enum LineHeight {
  SM = "1.2",
  MD = "1.4",
  LG = "1.6",
}
