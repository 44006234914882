import React from "react";
import { t } from "i18next";
import { Fade } from "react-awesome-reveal";

import { animationDuration, mailTo } from "shared/constants";
import { Btn, Container, Section, Text, Title } from "./styles";

export default function ShareYourNeeds() {
  return (
    <Fade duration={animationDuration.MD} direction="left" triggerOnce>
      <Section>
        <Container>
          <Title>{t("needs.title")}</Title>
          <Text>{t("needs.desc")}</Text>
          <Btn
            type="button"
            onClick={(e) => {
              window.location.href = mailTo;
              e.preventDefault();
            }}
          >
            {t("needs.btn")}
          </Btn>
        </Container>
      </Section>
    </Fade>
  );
}
