import React from "react";
import { t } from "i18next";
import { Fade } from "react-awesome-reveal";

import { animationDuration } from "shared/constants";
import { ReactComponent as Icon1 } from "assets/icons/icon1.svg";
import { ReactComponent as Icon2 } from "assets/icons/icon2.svg";
import { ReactComponent as Icon3 } from "assets/icons/icon3.svg";
import { ReactComponent as Icon4 } from "assets/icons/icon4.svg";
import { ReactComponent as Icon5 } from "assets/icons/icon5.svg";
import { ReactComponent as Icon6 } from "assets/icons/icon6.svg";
import { ReactComponent as Icon7 } from "assets/icons/icon7.svg";
import { ReactComponent as Icon8 } from "assets/icons/icon8.svg";
import { Item, List, Section, Text, Title } from "./styles";
import ServiceCard from "./ServiceCard/ServiceCard";

export default function HealthcareServices() {
  return (
    <Fade
      duration={animationDuration.LG}
      direction="up"
      triggerOnce
      cascade
      damping={0.1}
    >
      <Section>
        <Title id="services">{t("services.title")}</Title>
        <List>
          <Item>
            <ServiceCard
              icon={<Icon1 width={"40px"} height={"40px"} />}
              category={t("services.healthcare")}
              title={t("services.title1")}
            >
              <Text>{t("services.text1")}</Text>
            </ServiceCard>
          </Item>
          <Item>
            <ServiceCard
              icon={<Icon2 width={"40px"} height={"40px"} />}
              category={t("services.healthcare")}
              title={t("services.title2")}
            >
              <Text>{t("services.text2")}</Text>
            </ServiceCard>
          </Item>
          <Item>
            <ServiceCard
              icon={<Icon3 width={"40px"} height={"40px"} />}
              category={t("services.healthcare")}
              title={t("services.title3")}
            >
              <Text>{t("services.text3")}</Text>
            </ServiceCard>
          </Item>
          <Item>
            <ServiceCard
              icon={<Icon4 width={"40px"} height={"40px"} />}
              category={t("services.healthcare")}
              title={t("services.title4")}
            >
              <Text>{t("services.text4")}</Text>
            </ServiceCard>
          </Item>
          <Item>
            <ServiceCard
              icon={<Icon5 width={"40px"} height={"40px"} />}
              category={t("services.healthcare")}
              title={t("services.title5")}
            >
              <Text>{t("services.text5")}</Text>
            </ServiceCard>
          </Item>
          <Item>
            <ServiceCard
              icon={<Icon6 width={"40px"} height={"40px"} />}
              category={t("services.healthcare")}
              title={t("services.title6")}
            >
              <Text>{t("services.text6")}</Text>
            </ServiceCard>
          </Item>
          <Item>
            <ServiceCard
              icon={<Icon7 width={"40px"} height={"40px"} />}
              category={t("services.healthcare")}
              title={t("services.title7")}
            >
              <Text>{t("services.text7")}</Text>
            </ServiceCard>
          </Item>
          <Item>
            <ServiceCard
              icon={<Icon8 width={"40px"} height={"40px"} />}
              category={t("services.healthcare")}
              title={t("services.title8")}
            >
              <Text>{t("services.text8")}</Text>
            </ServiceCard>
          </Item>
        </List>
      </Section>
    </Fade>
  );
}
