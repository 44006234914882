import { styled } from "styled-components";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";
import { PrimaryColor } from "shared/styles/colors.constant";
import mask from "assets/icons/mask-1.svg";

export const Section = styled.section`
  width: 100%;
  margin-bottom: 50px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  min-width: 80%;
  width: 80%;
  height: 100%;
  margin: 20px auto 0 auto;
  padding: 30px 50px 0 50px;
  display: flex;
  border-radius: 20px;
  background: ${PrimaryColor.BASE_WHITE};
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.17);
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Title = styled.h2`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.MEDIUM_32};
  line-height: ${LineHeight.SM};
  color: ${PrimaryColor.DARK_GREY};
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const AccentText = styled.span`
  color: ${PrimaryColor.BASE_RED};
`;

export const Text = styled.p`
  margin-top: 30px;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_16};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.DARK_GREY};
`;

export const Btn = styled.button`
  padding: 11px 50px;
  margin-top: 20px;
  border-radius: 50px;
  background: ${PrimaryColor.SECONDARY_GREEN};
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_18};
  line-height: ${LineHeight.SM};
  font-weight: 500;
  color: ${PrimaryColor.BASE_WHITE};
  border: none;
  height: fit-content;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${PrimaryColor.BASE_GREEN};
  }
`;

export const TextContainer = styled.div`
  width: 70%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const PhotoContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Photo = styled.img`
  -webkit-mask-image: url(${mask});
  -webkit-mask-size: contain;
  -webkit-mask-position: custom;
  -webkit-mask-position-x: 60%;
  -webkit-mask-repeat: no-repeat;
  width: 142px;
  height: 142px;
  z-index: 100;
`;

export const SpecBox = styled.div`
  border-style: solid;
  border-width: 2px;
  border-color: ${PrimaryColor.BASE_GREEN};
  border-radius: 10px;
  padding: 60px 30px 15px 30px;
  position: relative;
  top: -50px;
`;

export const Name = styled.p`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_18};
  line-height: ${LineHeight.MD};
  font-weight: 500;
  color: ${PrimaryColor.TITLE_BLACK};
  text-align: center;
`;

export const Speciality = styled.p`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_16};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.DARK_GREY};
  text-align: center;
`;
