import React from "react";
import { t } from "i18next";
import { Fade } from "react-awesome-reveal";

import logo from "assets/icons/logo-original.svg";
import { animationDuration, mailTo } from "shared/constants";
import {
  Btn,
  Header,
  Link,
  LogoBox,
  LogoImg,
  NavigationItem,
  NavigationList,
} from "./styles";

export default function HeaderComponent() {
  return (
    <Header>
      <Fade duration={animationDuration.XS} triggerOnce>
        <LogoBox>
          <Link href="#nav">
            <LogoImg src={logo} alt={"SoftHealth Logo"} />
          </Link>
        </LogoBox>
        <NavigationList>
          <NavigationItem>
            <Link href="#services">{t("Services")}</Link>
          </NavigationItem>
          <NavigationItem>
            <Link href="#howwework">{t("How we work")}</Link>
          </NavigationItem>
          <NavigationItem>
            <Link href="#portfolio">{t("Portfolio")}</Link>
          </NavigationItem>
          <NavigationItem>
            <Link href="#faq">{t("FAQ")}</Link>
          </NavigationItem>
          <Btn
            type="button"
            onClick={(e) => {
              window.location.href = mailTo;
              e.preventDefault();
            }}
          >
            {t("Contact Us")}
          </Btn>
        </NavigationList>
      </Fade>
    </Header>
  );
}
