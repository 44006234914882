import React from "react";
import { t } from "i18next";
import { Fade } from "react-awesome-reveal";

import photo1 from "assets/images/portfolio-1.png";
import photo2 from "assets/images/portfolio-2.png";
import photo3 from "assets/images/portfolio-3.png";
import { animationDuration } from "shared/constants";

import {
  Item,
  CardTitle,
  Container,
  ContentWrapper,
  DescriptionWrapper,
  Label,
  LabelWrapper,
  List,
  Section,
  Text,
  Title,
  ImageWrapper,
  Image,
} from "./styles";

export default function Portfolio() {
  return (
    <Fade duration={animationDuration.MD} triggerOnce cascade damping={0.1}>
      <Section>
        <Container>
          <Title id="portfolio">{t("portfolio.title")}</Title>
          <List>
            <Item>
              <LabelWrapper>
                <Label>{t("portfolio.cat")}</Label>
              </LabelWrapper>
              <ContentWrapper>
                <ImageWrapper>
                  <Image src={photo1} alt={t("portfolio.alt1")} />
                </ImageWrapper>
                <DescriptionWrapper>
                  <CardTitle>{t("portfolio.title1")}</CardTitle>
                  <Text>{t("portfolio.text1")}</Text>
                </DescriptionWrapper>
              </ContentWrapper>
            </Item>
            <Item>
              <LabelWrapper>
                <Label>{t("portfolio.cat")}</Label>
              </LabelWrapper>
              <ContentWrapper>
                <ImageWrapper>
                  <Image src={photo2} alt={t("portfolio.alt1")} />
                </ImageWrapper>
                <DescriptionWrapper>
                  <CardTitle>{t("portfolio.title2")}</CardTitle>
                  <Text>{t("portfolio.text2")}</Text>
                </DescriptionWrapper>
              </ContentWrapper>
            </Item>
            <Item>
              <LabelWrapper>
                <Label>{t("portfolio.cat")}</Label>
              </LabelWrapper>
              <ContentWrapper>
                <ImageWrapper>
                  <Image src={photo3} alt={t("portfolio.alt1")} />
                </ImageWrapper>
                <DescriptionWrapper>
                  <CardTitle>{t("portfolio.title3")}</CardTitle>
                  <Text>{t("portfolio.text3")}</Text>
                </DescriptionWrapper>
              </ContentWrapper>
            </Item>
          </List>
        </Container>
      </Section>
    </Fade>
  );
}
