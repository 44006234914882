import { styled } from "styled-components";
import { PrimaryColor } from "shared/styles/colors.constant";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";

export const Section = styled.section`
  width: 100%;
  margin-bottom: 50px;

  h2 {
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 60px;
      height: 4px;
      left: -80px;
      top: 20px;
      background: ${PrimaryColor.BASE_RED};
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
`;

export const FaqWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;

  .ant-collapse-header {
    align-items: center !important;
  }

  .ant-collapse-header-text {
    font-family: ${FontFamily.JOST} !important;
    font-size: ${FontSize.MEDIUM_22} !important;
    line-height: ${LineHeight.SM} !important;
    font-weight: 500 !important;
  }
`;

export const Container = styled.div`
  width: 80%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  position: relative;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.LARGE_38};
  line-height: ${LineHeight.SM};
  color: ${PrimaryColor.TITLE_BLACK};
  text-align: center;

  @media screen and (max-width: 768px) {
    padding-bottom: 15px;
    border-bottom: 4px solid ${PrimaryColor.BASE_RED};
  }
`;

export const Text = styled.p`
  display: inline-block;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_18};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.BLACK};
`;

export const List = styled.ul`
  list-style: circle;
  list-style-position: inside;
`;

export const Item = styled.li`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_18};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.BLACK};
`;
