import { styled } from "styled-components";
import { PrimaryColor } from "shared/styles/colors.constant";
import rectangle from "assets/icons/rectangle-icon.svg";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  background: ${PrimaryColor.BASE_WHITE};
  border: 5px solid transparent;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 15px 50px 0 rgba(0, 0, 0, 0.17);

  &:hover {
    border: 5px solid ${PrimaryColor.BASE_GREEN};
  }
`;

export const IconBox = styled.div`
  max-width: 70px;
  min-width: 70px;
  min-height: 80px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${rectangle});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  gap: 20px;
`;

export const Category = styled.p`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_15};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.LIGHT_GREY};
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 8px;
`;

export const Title = styled.h1`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_18};
  font-weight: 600;
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.DARK_GREY};
`;
