import React from "react";
import { t } from "i18next";
import { Fade } from "react-awesome-reveal";

import { animationDuration } from "shared/constants";
import { Item, List, Section, Text, Title } from "./styles";
import Card from "./Card/Card";

export default function HowWeWork() {
  return (
    <Fade duration={animationDuration.LG} triggerOnce>
      <Section>
        <Title id="howwework">{t("howwork.title")}</Title>
        <List>
          <Item>
            <Card category={t("howwork.cat1")} title={t("howwork.title1")}>
              <Text>{t("howwork.text1")}</Text>
            </Card>
          </Item>
          <Item>
            <Card category={t("howwork.cat2")} title={t("howwork.title2")}>
              <Text>{t("howwork.text2")}</Text>
            </Card>
          </Item>
          <Item>
            <Card category={t("howwork.cat3")} title={t("howwork.title3")}>
              <Text>{t("howwork.text3")}</Text>
            </Card>
          </Item>
        </List>
      </Section>
    </Fade>
  );
}
