export enum PrimaryColor {
  BASE_BLUE = "#003366",
  BASE_WHITE = "#FCFCFC",
  BASE_RED = "#C21E68",
  BASE_GREEN = "#008f6b",
  SECONDARY_GREEN = "#00B388",
  BLACK = "#000000",
  TITLE_BLACK = "#111111",
  DARK_GREY = "#444444",
  LIGHT_GREY = "#C1C1C1",
  FOOTER_BACKGROUND = "#2f303a"
}
