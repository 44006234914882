import React from "react";
import { Fade } from "react-awesome-reveal";
import {
  GlobalOutlined,
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { animationDuration, mailTo } from "shared/constants";
import { ReactComponent as LogoWhite } from "assets/icons/white.svg";

import {
  Contact,
  ContactsBlock,
  Container,
  FooterStyled,
  LinkStyled,
  LogoBox,
  LogoLink,
  Title,
} from "./styles";
import { t } from "i18next";

export default function Footer() {
  return (
    <Fade style={{ width: "100%" }} duration={animationDuration.SM} triggerOnce>
      <FooterStyled>
        <Title>{t("footer.title")}</Title>

        <Container>
          <ContactsBlock>
            <Contact>
              <HomeOutlined />
              <LinkStyled
                href="https://maps.app.goo.gl/rzMrXscyZwJhEw6e6"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("footer.address")}
              </LinkStyled>
            </Contact>
            <Contact>
              <PhoneOutlined />
              <LinkStyled href="tel:+41435082604">{t("footer.tel")}</LinkStyled>
            </Contact>
          </ContactsBlock>
          <ContactsBlock>
            <Contact>
              <MailOutlined />
              <LinkStyled href={mailTo}>{t("footer.mail")}</LinkStyled>
            </Contact>
            <Contact>
              <GlobalOutlined />
              <LinkStyled
                href="http://doctors365.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("footer.www")}
              </LinkStyled>
            </Contact>
          </ContactsBlock>
          <ContactsBlock>
            <LogoLink href="#nav">
              <LogoBox>
                <LogoWhite height={"40px"} />
              </LogoBox>
            </LogoLink>
          </ContactsBlock>
        </Container>
      </FooterStyled>
    </Fade>
  );
}
