import React from "react";
import { t } from "i18next";
import { Fade } from "react-awesome-reveal";

import { animationDuration } from "shared/constants";
import pic from "assets/images/photo_2021-09-28.jpeg";
import {
  Section,
  Title,
  Text,
  IconBox,
  TextWrapper,
  Container,
  Image,
} from "./styles";

export default function BlueTextSection() {
  return (
    <Fade direction="up" duration={animationDuration.LG} triggerOnce>
      <Section>
        <Container>
          <TextWrapper>
            <Title>{t("bluetext.title")}</Title>
            <Text>{t("bluetext.text")}</Text>
          </TextWrapper>
          <IconBox>
            <Image src={pic} alt={t("bluetext.alt")} />
          </IconBox>
        </Container>
      </Section>
    </Fade>
  );
}
