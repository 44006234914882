import { styled } from "styled-components";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";
import { PrimaryColor } from "shared/styles/colors.constant";
import bgPic from "assets/images/main-bg.webp";

export const Section = styled.section`
  width: 100%;
  padding-top: 150px;
  min-height: 560px;
  background-image: linear-gradient(
      to right,
      rgba(44, 61, 48, 0.7),
      rgba(47, 66, 52, 0.7)
    ),
    url(${bgPic});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

export const Container = styled.div`
  min-width: 80%;
  width: 80%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.LARGE_48};
  line-height: ${LineHeight.SM};
  color: ${PrimaryColor.BASE_WHITE};
  text-align: center;
`;

export const Text = styled.p`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_18};
  line-height: ${LineHeight.MD};
  color: ${PrimaryColor.BASE_WHITE};
  text-align: center;
  margin-top: 60px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
`;

export const ImgWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const Img = styled.img`
  max-width: 93px;
  max-height: 106px;
  width: 100%;
  height: 100%;
`;

export const Btn = styled.button`
  padding: 11px 5%;
  border-radius: 50px;
  background: ${PrimaryColor.SECONDARY_GREEN};
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_18};
  line-height: ${LineHeight.SM};
  font-weight: 500;
  color: ${PrimaryColor.BASE_WHITE};
  border: none;
  height: fit-content;
  white-space: nowrap;

  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: ${PrimaryColor.BASE_GREEN};
  }

  @media screen and (max-width: 768px) {
    font-size: ${FontSize.VW_MD};
  }
`;
