import React from "react";
import { t } from "i18next";
import { Fade } from "react-awesome-reveal";

import { animationDuration, videoLink } from "shared/constants";
import clientPhoto from "assets/images/AndrejStudencan.webp";
import poster from "assets/images/poster-video.jpg";
import {
  ClientTitle,
  Container,
  Description,
  JobTitle,
  PhotoWrapper,
  Section,
  TextWrapper,
  Title,
  Video,
  VideoWrapper,
} from "./styles";

export default function Testimonial() {
  return (
    <Fade duration={animationDuration.SM} triggerOnce>
      <Section>
        <Title id="portfolio">{t("client.title1")}</Title>
        <Container>
          <TextWrapper>
            <PhotoWrapper>
              <img
                src={clientPhoto}
                alt={t("client.alt")}
                width={120}
                height={120}
              />
            </PhotoWrapper>
            <ClientTitle>{t("client.name")}</ClientTitle>
            <JobTitle>{t("client.prof")}</JobTitle>
            <Description>{t("client.desc")}</Description>
          </TextWrapper>
          <VideoWrapper>
            <Video
              src={videoLink}
              controlsList="nodownload"
              poster={poster}
              controls
            ></Video>
          </VideoWrapper>
        </Container>
      </Section>
    </Fade>
  );
}
