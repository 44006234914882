import { styled } from "styled-components";
import { PrimaryColor } from "shared/styles/colors.constant";
import { FontFamily, FontSize, LineHeight } from "shared/styles/fonts.constant";

export const Section = styled.section`
  padding: 30px 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  background-image: linear-gradient(257deg, #17c99c 10%, #038d6d 90%);
  h2 {
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 60px;
      height: 4px;
      left: -80px;
      top: 20px;
      background: ${PrimaryColor.BASE_RED};

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
`;

export const Title = styled.h2`
  position: relative;
  display: inline-block;
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.LARGE_38};
  line-height: ${LineHeight.SM};
  color: ${PrimaryColor.BASE_WHITE};
  text-align: center;

  @media screen and (max-width: 768px) {
    padding-bottom: 15px;
    border-bottom: 4px solid ${PrimaryColor.BASE_RED};
  }
`;

export const List = styled.ul`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export const Item = styled.li`
  width: calc((88%) / 3);
  min-height: 320px;
  margin: 30px 2%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Text = styled.p`
  font-family: ${FontFamily.JOST};
  font-size: ${FontSize.SMALL_16};
  line-height: ${LineHeight.SM};
  color: ${PrimaryColor.BASE_WHITE};
`;
