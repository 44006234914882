import React from "react";
import { Category, Container, Header, Title } from "./styles";

interface IProps {
  children: React.ReactNode;
  category: string;
  title: string;
}

export default function Card({ children, category, title }: IProps) {
  return (
    <Container>
      <Header>
        <div>
          <Category>{category}</Category>
          <Title>{title}</Title>
        </div>
      </Header>
      {children}
    </Container>
  );
}
